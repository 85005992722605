import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom'
import Home from './Home';
import Terminos from './Terminos';
function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/terms' element={<Terminos/>}/>
      </Routes>
    </HashRouter>
  );
}

export default App;
