import logo from "./images/Logo.jpg";
import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import conociendoba from "./images/conociendoba.jpg";
import delfino from "./images/delfino.jpg";
import oddone from "./images/oddone.jpg";
import Confetti from "react-confetti";
import img1 from './images/1-PhotoRoom.png-PhotoRoom.png'
import img2 from './images/2-PhotoRoom.png-PhotoRoom.png'
import img3 from './images/3-PhotoRoom.png-PhotoRoom.png'
import img4 from './images/4-PhotoRoom.png-PhotoRoom.png'
import img5 from './images/5-PhotoRoom.png-PhotoRoom.png'
import img6 from './images/6-PhotoRoom.png-PhotoRoom.png'
import img7 from './images/7-PhotoRoom.png-PhotoRoom.png'
import img9 from './images/9-PhotoRoom.png-PhotoRoom.png'
import { useNavigate } from "react-router-dom";
export default function Home() {
    const [descuentos, setDescuentos] = useState([]);
    useEffect(() => {
        axios.get(`https://sqdrpyp.online/api/gerencia/code`).then((res) => {
            const ra = res.data
            setDescuentos(ra.filter((r) => r.boolean === 0))
        });
    }, [descuentos]);
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(
        ["renderizado"],
        ["cantidad"],
        ["postId"],
        ["imagenPost"],
        ["imagenPerfil"],
        ["nombreGanador1"],
        ["textoGanador1"],
        ["fotoGanador1"],
        ["nombreGanador2"],
        ["textoGanador2"],
        ["fotoGanador2"],
        ["nombreGanador3"],
        ["textoGanador3"],
        ["fotoGanador3"],
        ["nombreGanador4"],
        ["textoGanador4"],
        ["fotoGanador4"],
        ["nombreGanador5"],
        ["textoGanador5"],
        ["fotoGanador5"],
        ["nombreGanador6"],
        ["textoGanador6"],
        ["fotoGanador6"],
        ["nombreGanador7"],
        ["textoGanador7"],
        ["fotoGanador7"],
        ["nombreGanador8"],
        ["textoGanador8"],
        ["fotoGanador8"],
        ["nombreGanador9"],
        ["textoGanador9"],
        ["fotoGanador9"],
        ["nombreGanador10"],
        ["textoGanador10"],
        ["fotoGanador10"],
        ["nombrePerfil"],
        ['nombreSorteo'],
        ['ganadores'],
        ['menciones'],
        ['wait'],
        ['renderizadoDos']
    );
    const [reglas, setReglas] = useState({ nombre: null, win: 1, ment: 5 });
    const btnBurger = { border: "none" };
    const iconoMercadoPago = { heigth: "40px", width: "100px" };
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com.*\/p\/)([\d\w\-_]+)(?:\/)?(\?.*)?$/;
    const regex1 = /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com.*\/reel\/)([\d\w\-_]+)(?:\/)?(\?.*)?$/;
    const cuadroGanador1 = {
        height: "110px",
        border: '2px solid blueviolet',
        borderRadius: '0.375rem',
        maxWidth: '300px'
    };
    const [url, setUrl] = useState({ url: "" });
    const [wait, setWait] = useState(false);
    const [renderizado, setRenderizado] = useState(null)
    //renderizado 1
    useEffect(() => {
        if (renderizado === null) {
            setRenderizado(1)
        }
    }, [renderizado]);
    const btnCantComentarios = async (e) => {
        e.preventDefault();
        setWait(true)
        if (regex.exec(url.url) !== null) {
            let match = regex.exec(url.url)
            await axios.get(`https://youtogift.com/api/instagram/post-info/${match[1]}`).then(async (res) => {
                await axios.post(`https://apifotos.agsseguros.online/api/fotos/upload`, { imageUrl: res.data.data.image_versions2.candidates[0].url, imageName: `${res.data.data.code}-imagenPost.jpg` })
                console.log(`cargando ${res.data.data.code}`)
                await axios.post(`https://apifotos.agsseguros.online/api/fotos/upload`, { imageUrl: res.data.data.owner.profile_pic_url, imageName: `${res.data.data.code}-imagenPerfil.jpg` })
                console.log(`cargando ${res.data.data.code}`)
                setCookie('code', res.data.data.code, { path: "/" })
                setCookie('cantidad', res.data.data.comment_count, { path: "/" })
                setCookie('postId', res.data.data.caption.user_id, { path: "/" })
                setCookie('nombrePerfil', res.data.data.caption.user.username, { path: "/" })
                setCookie('imagenPost', res.data.data.image_versions2.candidates[0].url, { path: "/" })
                setCookie('imagenPerfil', res.data.data.image_versions2.candidates[0].url, { path: "/" })
                setCookie('pay', 0, { path: "/" })
                setCookie('renderizadoDos', 1, { path: "/" })
                if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 50000) {
                    setCookie('precio', 5000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 10000 && Number(res.data.data.comment_count) < 50000) {
                    setCookie('precio', 4000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 5000 && Number(res.data.data.comment_count) < 10000) {
                    setCookie('precio', 3000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 300 && Number(res.data.data.comment_count) < 5000) {
                    setCookie('precio', 2000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) < 300) {
                    setCookie('precio', 0, { path: "/" })
                }
                setTimeout(() => {
                    setWait(false)
                    window.location.reload()
                }, 5000);
            })
        } else if (regex1.exec(url.url)) {
            let match1 = regex1.exec(url.url)
            await axios.get(`https://youtogift.com/api/instagram/post-info/${match1[1]}`).then(async (res) => {
                await axios.post(`https://apifotos.agsseguros.online/api/fotos/upload`, { imageUrl: res.data.data.image_versions2.candidates[0].url, imageName: `${res.data.data.caption.user.code}-imagenPost.jpg` })
                console.log(`cargando ${res.data.data.code}`)
                await axios.post(`https://apifotos.agsseguros.online/api/fotos/upload`, { imageUrl: res.data.data.owner.profile_pic_url, imageName: `${res.data.data.caption.user.code}-imagenPerfil.jpg` })
                console.log(`cargando ${res.data.data.code}`)
                setCookie('code', res.data.data.code, { path: "/" })
                setCookie('cantidad', res.data.data.comment_count, { path: "/" })
                setCookie('postId', res.data.data.caption.user_id, { path: "/" })
                setCookie('nombrePerfil', res.data.data.caption.user.username, { path: "/" })
                setCookie('imagenPost', res.data.data.image_versions2.candidates[0].url, { path: "/" })
                setCookie('imagenPerfil', res.data.data.image_versions2.candidates[0].url, { path: "/" })
                setCookie('pay', 0, { path: "/" })
                setCookie('renderizadoDos', 1, { path: "/" })
                if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 50000) {
                    setCookie('precio', 5000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 10000 && Number(res.data.data.comment_count) < 50000) {
                    setCookie('precio', 4000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 5000 && Number(res.data.data.comment_count) < 10000) {
                    setCookie('precio', 3000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) >= 300 && Number(res.data.data.comment_count) < 5000) {
                    setCookie('precio', 2000, { path: "/" })
                } else if (res.data.data.comment_count !== null && Number(res.data.data.comment_count) < 300) {
                    setCookie('precio', 0, { path: "/" })
                }
                setTimeout(() => {
                    setWait(false)
                    window.location.reload()
                }, 5000);
            })
        }
    };
    // renderizado dos
    useEffect(() => {
        if (Number(cookies.renderizadoDos) === 1) {
            setRenderizado(2)
            setCookie('renderizadoDos', 0, { path: "/" })
        }
    }, [renderizado, cookies.renderizadoDos, setCookie]);
    const [desc, setDesc] = useState({ desc: null })
    let cd = descuentos.filter((d) => d.code === desc.desc)
    const FuncionComprar = async () => {
        if (cd[0] !== undefined) {
            let prec = Number(cookies.precio) - (Number(cookies.precio) * Number(cd[0].desc))
            if (prec === 0) {
                setCookie('pay', 1, { path: "/" })
                await axios.post(`https://sqdrpyp.online/api/gerencia/code/update`, { id: cd[0].id });
                window.location.reload()
            } else {
                setCookie('pay', 1, { path: "/" })
                setCookie('market', 1, { path: "/" })
                setCookie('render', 1, { path: "/" })
                await axios.post(`https://sqdrpyp.online/api/gerencia/code/update`, { id: cd[0].id });
                window.location.reload()
            }
        } else if (Number(cookies.precio) === 0) {
            setCookie('pay', 1, { path: "/" })
            window.location.reload()
        } else {
            setCookie('pay', 1, { path: "/" })
            setCookie('market', 1, { path: "/" })
            setCookie('render', 1, { path: "/" })
            window.location.reload()
        }
    };
    useEffect(() => {
        if (Number(cookies.render) === 1) {
            setRenderizado(2)
        }
    }, [renderizado, cookies.render, setRenderizado]);
    //renderizado mercado pago
    useEffect(() => {
        async function ab() {
            if (Number(cookies.market) === 1) {
                const response = await axios.post("https://apifotos.agsseguros.online/api/fotos/upload/app", {
                    nombre: "App Sorteo Simple",
                    precio: Number(cookies.precio),
                    cantidad: 1,
                })
                setCookie('render', 0, { path: "/" })
                setCookie('market', 0, { path: "/" })
                window.location.href = response.data;
            }
        }
        ab()
    }, [cookies.market, cookies.precio, setCookie]);
    //renderizado 3
    useEffect(() => {
        if (Number(cookies.pay) === 1 && Number(cookies.market !== 1)) {
            setRenderizado(3)
        }
    }, [renderizado, cookies.pay, cookies.market, setRenderizado]);
    const btnGanador = async (e) => {
        e.preventDefault();
        setWait(true);
        await axios.get(`https://api-qa.likester.com/api/scrape/comments?post_id=${cookies.postId}&shortcode=${cookies.code}&winners=15`)
            .then((res) => {
                setCookie("nombreSorteo", reglas.nombre, { path: "/" });
                setCookie("ganadores", reglas.win, { path: "/" });
                setCookie("menciones", reglas.ment, { path: "/" });
                setCookie('pay', 2, { path: "/" })
                for (let index = 0; index < res.data.length; index++) {
                    console.log(res.data);
                    setCookie(`textoGanador${index}`, res.data[index].text, { path: "/" });
                    setCookie(`nombreGanador${index}`, res.data[index].user.username, { path: "/", });
                    setCookie(`fotoGanador${index}`, res.data[index].user.profile_pic_url, { path: "/" });
                    axios.post(`https://apifotos.agsseguros.online/api/fotos/upload`, { imageUrl: res.data[index].user.profile_pic_url, imageName: `${cookies.code}-fotoGanador${index}.jpg` })
                    console.log(`cargando ${index}`)
                }
                setTimeout(() => {
                    window.location.reload()
                }, 50000);
            });
    };
    useEffect(() => {
        if (Number(cookies.pay) === 2) {
            setRenderizado(4)
        }
    }, [renderizado, cookies.pay, setRenderizado]);
    const back2 = { height: "80px", width: "80px", borderRadius: "100%" };
    const [preg, setPreguntas] = useState(0);
    const pregUno = (e) => {
        setPreguntas(e);
    };
    const btnMasWin = () => {
        let a = Number(reglas.win) + 1
        if (Number(reglas.win) < 5) {
            setReglas((prevState) => ({
                ...prevState,
                win: a
            }))
        }
    }
    const btnMenosWin = () => {
        let a = Number(reglas.win) - 1
        if (reglas.win > 1) {
            setReglas((prevState) => ({
                ...prevState,
                win: a
            }))
        } 
    }
    const btnMasMent = () => {
        let a = Number(reglas.ment) + 1
        setReglas((prevState) => ({
            ...prevState,
            ment: a
        }))
    }
    const btnMenosMent = () => {
        let a = Number(reglas.ment) - 1
        setReglas((prevState) => ({
            ...prevState,
            ment: a
        }))
    }
    const a = new Date().toLocaleString("es-AR", { timeZone: "America/Argentina/Buenos_Aires" }).replace(',', '')
    const view = () => {
        switch (cookies.ganadores) {
            case 1:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador0.jpg`} alt="" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador0}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador0.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador0}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador1.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador1}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador0.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador0}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador1.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador1}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador2.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador2}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador0.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador0}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador1.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador1}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador2.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador2}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador3.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador3}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador0.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador0}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador1.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador1}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador2.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador2}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador3.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador3}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador4.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde text-white text-center shadow">Ganador</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador4}</h6>
                            </div>
                        </div>
                    </div>
                )
            default:
                break;
        }
    }
    const view1 = () => {
        switch (Number(cookies.menciones)) {
            case 1:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador0.jpg`} alt="" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador8.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador8}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador8.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador8}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador9.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador9}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 6:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador8.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador8}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador9.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador9}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador10.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador10}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 7:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador8.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador8}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador9.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador9}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador10.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador10}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador11.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador11}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 8:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador8.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador8}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador9.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador9}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador10.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador10}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador11.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador11}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador12.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador12}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 9:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador8.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador8}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador9.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador9}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador10.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador10}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador11.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador11}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador12.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador12}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador13.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador13}</h6>
                            </div>
                        </div>
                    </div>
                )
            case 10:
                return (
                    <div className="row me-3 ms-3">
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador5.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador5}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador6.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador6}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador7.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador7}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador8.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador8}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador9.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador9}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador10.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador10}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador11.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador11}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador12.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador12}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador13.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador13}</h6>
                            </div>
                        </div>
                        <div style={cuadroGanador1} className="m-auto pt-2 rounded mb-3 row">
                            <div className="col-6">
                                <div className="">
                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-fotoGanador14.jpg`} alt="fotoGanador" style={back2} className="shadow" />
                                    <div className="cuadroGanadorVerde1 text-white text-center shadow">Suplente</div>
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <h6 className="text-center center">{cookies.nombreGanador14}</h6>
                            </div>
                        </div>
                    </div>
                )
            default:
                break;
        }
    }
    const ActiveView = () => {
        switch (renderizado) {
            case 1:
                return (
                    <section class="seccionForm" id="comenzar">
                        <div className="center">
                            <div className="w-75">
                                <h2 class="center texto text-white mt-4">
                                    Creá sorteos en Instagram al instante!
                                </h2>
                                <h3 class="center texto1 text-white">
                                    Sorteo Simple es una plataforma rápida, segura y sencilla para
                                    hacer tus sorteos.
                                </h3>
                                <div className="center mt-3 text-center">
                                    <h4 className="text-white pe-2">Pagá con</h4>
                                    <img src="https://logotipoz.com/wp-content/uploads/2021/10/version-horizontal-large-logo-mercado-pago.webp" alt="mercadoPago" style={iconoMercadoPago}></img>
                                </div>
                                <div className="instagramContainer m-auto bg-white mt-5">
                                    <div className="instagramHeaderContainer">
                                        <div className="m-auto">
                                            <div className="nroContainer center">
                                                <div className="nro center">
                                                    1
                                                </div>
                                            </div>
                                            <div className="textoHeaderVioleta text-center">Escanear</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    2
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Pago</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    3
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Ajustes</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    4
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Certificado</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <form action="" class="mt-3 mb-3">
                                        <div class="inputSorteoContainer me-3 ms-3 d-flex align-items-center text-center border text-center ps-2 pe-2">
                                            <p className="arroba center m-0 ">@</p>
                                            <input
                                                type="text"
                                                class="inputSorteo ps-3 w-100"
                                                placeholder="INGRESA LINK DE LA PUBLICACION"
                                                onChange={(e) =>
                                                    setUrl((prevState) => ({
                                                        ...prevState,
                                                        url: e.target.value,
                                                    }))
                                                }
                                            />
                                        </div>
                                        {wait ? <div className="center mt-1 pe-3 ps-3 text-center"><div className="botonForm1 text-center center"><div className={wait ? " center" : "d-none"}>
                                            <div class="spinner-border text-light" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div></div>
                                        </div></div> : <div class="center mt-1 pe-3 ps-3">
                                            <button
                                                type="submit"
                                                class="botonForm1"
                                                onClick={(e) => btnCantComentarios(e)}
                                            >
                                                COMENZAR
                                            </button>
                                        </div>}
                                    </form>
                                    <div className="postContainer shadow-lg border rounded bg-white mt-2 w-75">
                                        <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                            <div className="imagenPerfilContainer"></div>
                                            <div className="tresPuntos">...</div>
                                        </div>
                                        <div className="bodyPost center">
                                            <div className="imagenPostContainer text-center">
                                                <img src="https://viralyft.com/tools/_next/static/media/no-post.8f602c67.png" alt="" className="" />
                                                <h6>No Instagram <br />Post shown</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="textoAdvertencia center text-center mt-3">UNA VEZ QUE REALICES UN SORTEO CON TU USUARIO, <br />NO PODRÁS VOLVER A REALIZAR OTRO SORTEO POR 24 HORAS.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            case 2:
                return (
                    <section class="seccionForm" id="comenzar">
                        <div className="row center">
                            <div className="w-75">
                                <div className="instagramContainer13 bg-white mt-5 m-auto">
                                    <div className="instagramHeaderContainer">
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    1
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Escanear</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainer center">
                                                <div className="nro center">
                                                    2
                                                </div>
                                            </div>
                                            <div className="textoHeaderVioleta text-center">Pago</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    3
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Ajustes</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    4
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Certificado</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="center m-auto me-3 ms-3">
                                        <div className="cartaCantidad bg-white shadow w-100 me-1">
                                            <div className="center text-center mt-3">
                                                <i class="bi bi-people-fill iconoPersona"></i>
                                                <h6 className="iconoPersona1 text-center center m-0 ps-1">{cookies.cantidad}</h6>
                                            </div>
                                            <p className="m-0 center textoCartaCantidad text-center">Número de usuarios<br /> que participan del sorteo</p>
                                        </div>
                                        <div className="cartaCantidad bg-white shadow w-100 ms-1">
                                            <div className="d-flex center color">
                                                <h3 className="arsPrecio1">ARS</h3>
                                                <h1 className="precio1">{cd[0] ? Number(cookies.precio) - (Number(cookies.precio) * Number(cd[0].desc)) : Number(cookies.precio)}</h1>
                                            </div>
                                            <div className="me-1 ms-1">
                                                <input type="text" className="form-control form-control-sm" placeholder="Ingrese su cupon de descuento" onChange={(e) =>
                                                    setDesc((prevState) => ({
                                                        ...prevState,
                                                        desc: e.target.value,
                                                    }))
                                                } />
                                            </div>
                                            {Number(cookies.precio) >= 300 ? <div className="center mt-1 ms-1 me-1"><button className="btn btn-info text-white" onClick={(e) => FuncionComprar()}>Ir a Pagar</button></div> : <div className="center mt-1"><button className="btn btn-info text-white" onClick={(e) => FuncionComprar()}>Siguiente Paso</button></div>}
                                        </div>
                                    </div>
                                    <div className="postContainer shadow-lg border rounded bg-white mt-2 w-75">
                                        {cookies.imagenPerfil ?
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="center">
                                                    <div className="imagenPerfilContainer">
                                                        <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPerfil.jpg`} alt="" className="fotoPerfil rounded-circle" />
                                                    </div>
                                                    <h6 className="center ps-2 text-center">{cookies.nombrePerfil}</h6>
                                                </div>
                                            </div> :
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="imagenPerfilContainer"></div>
                                                <div className="tresPuntos">...</div>
                                            </div>}
                                        <div className="bodyPost center">
                                            {cookies.imagenPost ?
                                                <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPost.jpg`} alt="" className="fotoPost" /> :
                                                <div className="imagenPostContainer text-center">
                                                    <img src="https://viralyft.com/tools/_next/static/media/no-post.8f602c67.png" alt="pic" className="" />
                                                    <h6>No Instagram <br />Post shown</h6>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            case 3:
                return (
                    <section class="seccionForm">
                        <div className="row center">
                            <div className="w-75">
                                <div className="instagramContainer2 bg-white mt-5 m-auto shadow-lg d-none d-md-block">
                                    <div className="instagramHeaderContainer">
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    1
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Escanear</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    2
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Pago</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainer center">
                                                <div className="nro center">
                                                    3
                                                </div>
                                            </div>
                                            <div className="textoHeaderVioleta text-center">Ajustes</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    4
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Certificado</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="">
                                        <div className="row me-3 ms-3 center">
                                            <div className="col-12 col-sm-4 cartaCantidad bg-white shadow me-1 mb-3">
                                                <div className="center mt-3">
                                                    <i class="bi bi-people-fill iconoPersona"></i>
                                                    <h6 className="iconoPersona1 text-center center m-0 ps-1">{cookies.cantidad}</h6>
                                                </div>
                                                <p className="m-0 center textoCartaCantidad text-center">Número de usuarios<br /> que participan del sorteo</p>
                                            </div>
                                            <div className="col-12 col-sm-4 cartaCantidad bg-white shadow ms-1 mb-3">
                                                <div className="mt-1 m-0">
                                                    <label htmlFor="center">Nombre Sorteo</label>
                                                    <input type="text" className="form-control form-control-sm" onChange={(e) =>
                                                        setReglas((prevState) => ({
                                                            ...prevState,
                                                            nombre: e.target.value,
                                                        }))
                                                    } />
                                                </div>
                                                <div className="center">
                                                    <div className="me-1 w-100">
                                                        <label htmlFor="center">Ganadores</label>
                                                        <div className="border p-2 d-flex justify-content-around align-items-center">
                                                            <button className="botonMas text-white" disabled={Number(reglas.win) === 5 ? true : false} onClick={(e) => btnMasWin(e)}>+</button>
                                                            <p className="m-0">{reglas.win}</p>
                                                            <button className="botonMenos" disabled={Number(reglas.win) === 1 ? true : false} onClick={(e) => btnMenosWin(e)}>-</button>
                                                        </div>
                                                    </div>
                                                    <div className="ms-1 w-100">
                                                        <label htmlFor="center">Suplentes</label>
                                                        <div className="border p-2 d-flex justify-content-around align-items-center">
                                                            <button className="botonMas text-white" disabled={reglas.ment === 10 ? true : false} onClick={(e) => btnMasMent()}>+</button>
                                                            <p className="m-0">{reglas.ment}</p>
                                                            <button className="botonMenos" disabled={reglas.ment === 1 ? true : false} onClick={(e) => btnMenosMent()}>-</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {wait ? <div className="center mt-1 text-center">
                                            <div className="botonForm3 text-center center">
                                                <div class="spinner-border text-light" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div></div> :
                                            <div class="center mt-1 pe-3 ps-3">
                                                <button type="submit" class="botonForm3" onClick={(e) => btnGanador(e)}> IR AL GANADOR </button>
                                            </div>}
                                    </div>
                                    <div className="postContainer shadow border rounded bg-white mt-3 w-75">
                                        {cookies.imagenPerfil ?
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="center">
                                                    <div className="imagenPerfilContainer">
                                                        <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPerfil.jpg`} alt="" className="fotoPerfil rounded-circle" />
                                                    </div>
                                                    <h6 className="center ps-2 text-center text-break">{cookies.nombrePerfil}</h6>
                                                </div>
                                            </div> :
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="imagenPerfilContainer"></div>
                                                <div className="tresPuntos">...</div>
                                            </div>}
                                        <div className="bodyPost center">
                                            {cookies.imagenPost ?
                                                <div className="imagenPostContainer h-100 w-100">
                                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPost.jpg`} alt="" className="fotoPost" />
                                                </div> :
                                                <div className="imagenPostContainer text-center">
                                                    <img src="https://viralyft.com/tools/_next/static/media/no-post.8f602c67.png" alt="" className="" />
                                                    <h6>No Instagram <br />Post shown</h6>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="instagramContainer15 bg-white mt-5 m-auto shadow-lg d-block d-lg-none">
                                    <div className="instagramHeaderContainer">
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    1
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Escanear</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    2
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Pago</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainer center">
                                                <div className="nro center">
                                                    3
                                                </div>
                                            </div>
                                            <div className="textoHeaderVioleta text-center">Ajustes</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    4
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Certificado</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="">
                                        <div className="row me-3 ms-3 center">
                                            <div className="col-12 col-sm-4 cartaCantidad bg-white shadow me-1 mb-3">
                                                <div className="center mt-3">
                                                    <i class="bi bi-people-fill iconoPersona"></i>
                                                    <h6 className="iconoPersona1 text-center center m-0 ps-1">{cookies.cantidad}</h6>
                                                </div>
                                                <p className="m-0 center textoCartaCantidad text-center">Número de usuarios<br /> que participan del sorteo</p>
                                            </div>
                                            <div className="col-12 col-sm-4 cartaCantidad bg-white shadow ms-1 mb-3">
                                                <div className="mt-1 m-0">
                                                    <label htmlFor="center">Nombre Sorteo</label>
                                                    <input type="text" className="form-control form-control-sm" onChange={(e) =>
                                                        setReglas((prevState) => ({
                                                            ...prevState,
                                                            nombre: e.target.value,
                                                        }))
                                                    } />
                                                </div>
                                                <div className="center">
                                                    <div className="me-1 w-100">
                                                        <label htmlFor="center">Ganadores</label>
                                                        <div className="border p-2 d-flex justify-content-around align-items-center">
                                                            <button className="botonMas text-white" disabled={reglas.win === 5 ? true : false} onPointerEnter={(e) => btnMasWin()}>+</button>
                                                            <p className="m-0">{reglas.win}</p>
                                                            <button className="botonMenos" disabled={reglas.win === 1 ? true : false} onPointerEnter={(e) => btnMenosWin()}>-</button>
                                                        </div>
                                                    </div>
                                                    <div className="ms-1 w-100">
                                                        <label htmlFor="center">Suplentes</label>
                                                        <div className="border p-2 d-flex justify-content-around align-items-center">
                                                            <button className="botonMas text-white" disabled={reglas.ment === 10 ? true : false} onClick={(e) => btnMasMent()}>+</button>
                                                            <p className="m-0">{reglas.ment}</p>
                                                            <button className="botonMenos" disabled={reglas.ment === 1 ? true : false} onClick={(e) => btnMenosMent()}>-</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {wait ? <div className="center mt-1 text-center">
                                            <div className="botonForm3 text-center center">
                                                <div class="spinner-border text-light" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div></div> :
                                            <div class="center mt-1 pe-3 ps-3">
                                                <button
                                                    type="submit"
                                                    class="botonForm3"
                                                    onClick={(e) => btnGanador(e)}
                                                >
                                                    IR AL GANADOR
                                                </button>
                                            </div>}
                                    </div>
                                    <div className="postContainer shadow border rounded bg-white mt-3 w-75">
                                        {cookies.imagenPerfil ?
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="center">
                                                    <div className="imagenPerfilContainer">
                                                        <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPerfil.jpg`} alt="" className="fotoPerfil rounded-circle" />
                                                    </div>
                                                    <h6 className="center ps-2 text-center text-break">{cookies.nombrePerfil}</h6>
                                                </div>
                                            </div> :
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="imagenPerfilContainer"></div>
                                                <div className="tresPuntos">...</div>
                                            </div>}
                                        <div className="bodyPost center">
                                            {cookies.imagenPost ?
                                                <div className="imagenPostContainer h-100 w-100">
                                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPost.jpg`} alt="" className="fotoPost" />
                                                </div> :
                                                <div className="imagenPostContainer text-center">
                                                    <img src="https://viralyft.com/tools/_next/static/media/no-post.8f602c67.png" alt="" className="" />
                                                    <h6>No Instagram <br />Post shown</h6>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            case 4:
                return (
                    <section class="seccionForm">
                        <div className="center">
                            <div className="w-75">
                                <div className={(Number(cookies.ganadores) + Number(cookies.menciones) === 1) ? "instagramContainer3 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 2) ? "instagramContainer4 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 3) ? "instagramContainer5 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 4) ? "instagramContainer6 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 5) ? "instagramContainer7 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 6) ? "instagramContainer8 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 7) ? "instagramContainer9 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 8) ? "instagramContainer10 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 9) ? "instagramContainer11 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 10) ? "instagramContainer12 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 11) ? "instagramContainer20 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 12) ? "instagramContainer21 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 13) ? "instagramContainer22 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 13) ? "instagramContainer23 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 14) ? "instagramContainer23 bg-white mt-5 m-auto" : ((Number(cookies.ganadores) + Number(cookies.menciones) === 15) ? "instagramContainer24 bg-white mt-5 m-auto" : "instagramContainer bg-white mt-5 m-auto")))))))))))))))}>
                                    <div className="instagramHeaderContainer">
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    1
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Escanear</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    2
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Pago</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    3
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Ajustes</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerVerde center">
                                                <div className="nroVerde center">
                                                    4
                                                </div>
                                            </div>
                                            <div className="textoHeaderVerde text-center">Certificado</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <h2 className="color text-center mb-3">Felicitaciones<br />{cookies.nombreSorteo}</h2>
                                    <h6 className="m-0 center color mb-5">{a}</h6>
                                    {view()}
                                    {cookies.menciones > 0 ? <h3 className="mt-5 center color">Suplentes</h3> : <div></div>}
                                    {view1()}
                                    <div className="postContainer shadow-lg border rounded bg-white mt-2 w-75">
                                        {cookies.imagenPerfil ?
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="center">
                                                    <div className="imagenPerfilContainer">
                                                        <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPerfil.jpg`} alt="fotoPerfil" className="fotoPerfil rounded-circle" />
                                                    </div>
                                                    <h6 className="center ps-2 text-center">{cookies.nombrePerfil}</h6>
                                                </div>
                                            </div> :
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="imagenPerfilContainer"></div>
                                                <div className="tresPuntos">...</div>
                                            </div>}
                                        <div className="bodyPost center">
                                            {cookies.imagenPost ?
                                                <div className="imagenPostContainer h-100 w-100">
                                                    <img src={`https://apifotos.agsseguros.online/fotos/${cookies.code}-imagenPost.jpg`} alt="fotoPost" className="fotoPost" />
                                                </div> :
                                                <div className="imagenPostContainer text-center">
                                                    <img src="https://viralyft.com/tools/_next/static/media/no-post.8f602c67.png" alt="" className="" />
                                                    <h6>No Instagram <br />Post shown</h6>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="mt-3 center">
                                        <button onClick={(e) => empezar0()} className="botonForm3">Realizar otro Sorteo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Confetti />
                    </section>
                );
            default:
                return (
                    <section class="seccionForm">
                        <div className="center">
                            <div className="w-75">
                                <h2 class="center texto text-white mt-4">
                                    Creá sorteos en Instagram al instante!
                                </h2>
                                <h3 class="center texto1 text-white">
                                    Sorteo Simple es una plataforma rápida, segura y sencilla para
                                    hacer tus sorteos
                                </h3>
                                <div className="center mt-3 text-center">
                                    <h4 className="text-white pe-2">Paga con</h4>
                                    <img src="https://logotipoz.com/wp-content/uploads/2021/10/version-horizontal-large-logo-mercado-pago.webp" alt="mercadoPago" style={iconoMercadoPago}></img>
                                </div>
                                <div className="instagramContainer m-auto bg-white mt-5">
                                    <div className="instagramHeaderContainer">
                                        <div className="m-auto">
                                            <div className="nroContainer center">
                                                <div className="nro center">
                                                    1
                                                </div>
                                            </div>
                                            <div className="textoHeaderVioleta text-center">Escanear<br />Posteo</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    2
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Pago</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    3
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Ajustes</div>
                                        </div>
                                        <div className="m-auto">
                                            <div className="nroContainerBlanco center">
                                                <div className="nroBlanco center">
                                                    4
                                                </div>
                                            </div>
                                            <div className="textoHeader text-center">Certificado</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <form action="" class="mt-3 mb-3">
                                        <div class="inputSorteoContainer me-3 ms-3 d-flex align-items-center text-center border text-center ps-2 pe-2">
                                            <p className="arroba center m-0 ">@</p>
                                            <input
                                                type="text"
                                                class="inputSorteo ps-3 w-100"
                                                placeholder="INGRESA LINK DE LA PUBLICACION"
                                                onChange={(e) =>
                                                    setUrl((prevState) => ({
                                                        ...prevState,
                                                        url: e.target.value,
                                                    }))
                                                }
                                            />
                                        </div>
                                        {wait ? <div className="center mt-1 pe-3 ps-3 text-center"><div className="botonForm1 text-center center"><div className={wait ? " center" : "d-none"}>
                                            <div class="spinner-border text-light" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div></div>
                                        </div></div> : <div class="center mt-1 pe-3 ps-3">
                                            <button
                                                type="submit"
                                                class="botonForm"
                                                onClick={(e) => btnCantComentarios(e)}
                                            >
                                                COMENZAR
                                            </button>
                                        </div>}
                                    </form>
                                    <div className="postContainer shadow-lg border rounded bg-white mt-2 w-75">
                                        {cookies.imagenPerfil ?
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="center">
                                                    <div className="imagenPerfilContainer">
                                                        <img src={`http://localhost:8080/public/${cookies.nombrePerfil}-imagenPerfil.jpg`} alt="fotoPerfil" className="fotoPerfil rounded-circle" />
                                                    </div>
                                                    <h6 className="center ps-2 text-center">{cookies.nombrePerfil}</h6>
                                                </div>
                                            </div> :
                                            <div className="d-flex justify-content-between align-items-center ps-3 pe-3 headerPost">
                                                <div className="imagenPerfilContainer"></div>
                                                <div className="tresPuntos">...</div>
                                            </div>}
                                        <div className="bodyPost center">
                                            {cookies.imagenPost ?
                                                <div className="imagenPostContainer h-100 w-100">
                                                    <img src={`http://localhost:8080/public/${cookies.nombrePerfil}-imagenPost.jpg`} alt="fotoPost" className="fotoPost" />
                                                </div> :
                                                <div className="imagenPostContainer text-center">
                                                    <img src="https://viralyft.com/tools/_next/static/media/no-post.8f602c67.png" alt="pic" className="" />
                                                    <h6>No Instagram <br />Post shown</h6>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );;
        }
    };
    function empezar0() {
        removeCookie('code', { path: "/" })
        removeCookie('cantidad', { path: "/" })
        removeCookie('postId', { path: "/" })
        removeCookie('nombrePerfil', { path: "/" })
        removeCookie('imagenPost', { path: "/" })
        removeCookie('imagenPerfil', { path: "/" })
        removeCookie('pay', { path: "/" })
        removeCookie('renderizadoDos', { path: "/" })
        removeCookie('precio', { path: "/" })
        removeCookie('market', { path: "/" })
        removeCookie('render', { path: "/" })
        removeCookie("nombreSorteo", { path: "/" });
        removeCookie("ganadores", { path: "/" });
        removeCookie("menciones", { path: "/" });
        removeCookie(`textoGanador0`, { path: "/" });
        removeCookie(`nombreGanador0`, { path: "/", });
        removeCookie(`fotoGanador0`, { path: "/" });
        removeCookie(`textoGanador1`, { path: "/" });
        removeCookie(`nombreGanador1`, { path: "/", });
        removeCookie(`fotoGanador1`, { path: "/" });
        removeCookie(`textoGanador2`, { path: "/" });
        removeCookie(`nombreGanador2`, { path: "/", });
        removeCookie(`fotoGanador2`, { path: "/" });
        removeCookie(`textoGanador3`, { path: "/" });
        removeCookie(`nombreGanador3`, { path: "/", });
        removeCookie(`fotoGanador3`, { path: "/" });
        removeCookie(`textoGanador4`, { path: "/" });
        removeCookie(`nombreGanador4`, { path: "/", });
        removeCookie(`fotoGanador4`, { path: "/" });
        removeCookie(`textoGanador5`, { path: "/" });
        removeCookie(`nombreGanador5`, { path: "/", });
        removeCookie(`fotoGanador5`, { path: "/" });
        removeCookie(`textoGanador6`, { path: "/" });
        removeCookie(`nombreGanador6`, { path: "/", });
        removeCookie(`fotoGanador6`, { path: "/" });
        removeCookie(`textoGanador7`, { path: "/" });
        removeCookie(`nombreGanador7`, { path: "/", });
        removeCookie(`fotoGanador7`, { path: "/" });
        removeCookie(`textoGanador8`, { path: "/" });
        removeCookie(`nombreGanador8`, { path: "/", });
        removeCookie(`fotoGanador8`, { path: "/" });
        removeCookie(`textoGanador9`, { path: "/" });
        removeCookie(`nombreGanador9`, { path: "/", });
        removeCookie(`fotoGanador9`, { path: "/" });
        removeCookie(`textoGanador10`, { path: "/" });
        removeCookie(`nombreGanador10`, { path: "/", });
        removeCookie(`fotoGanador10`, { path: "/" });
        removeCookie(`textoGanador11`, { path: "/" });
        removeCookie(`nombreGanador11`, { path: "/", });
        removeCookie(`fotoGanador11`, { path: "/" });
        removeCookie(`textoGanador12`, { path: "/" });
        removeCookie(`nombreGanador12`, { path: "/", });
        removeCookie(`fotoGanador12`, { path: "/" });
        removeCookie(`textoGanador13`, { path: "/" });
        removeCookie(`nombreGanador13`, { path: "/", });
        removeCookie(`fotoGanador13`, { path: "/" });
        removeCookie(`textoGanador14`, { path: "/" });
        removeCookie(`nombreGanador14`, { path: "/", });
        removeCookie(`fotoGanador14`, { path: "/" });
        setTimeout(() => {
            window.location.reload()
        }, 1000);
    }
    return (
        <div>
            <nav class="navbar navbar-light bg-light fixed-top pe-5 ps-5" id="comenzar">
                <div class="container-fluid">
                    <div></div>
                    <div class="center">
                        <img src={logo} alt="logo" class="logoHeader" onClick={(e) => empezar0()} />
                    </div>
                    <button
                        class="navbar-toggler"
                        style={btnBurger}
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div
                        class="offcanvas offcanvas-end"
                        tabindex="-1"
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                    >
                        <div class="offcanvas-header center">
                            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                                Sorteo Simple
                            </h5>
                            <button
                                type="button"
                                class="btn-close text-reset m-0 p-0"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="offcanvas-body">
                            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li class="nav-item">
                                    <p class="linkAb" onClick={(e) => empezar0()}>
                                        Comenzar
                                    </p>
                                </li>
                                <li class="nav-item">
                                    <p class="linkAb" onClick={(e) => document.getElementById('precio').scrollIntoView({ behavior: 'smooth' })}>
                                        Precios
                                    </p>
                                </li>
                                <li class="nav-item">
                                    <p class="linkAb" onClick={(e) => document.getElementById('utilizar').scrollIntoView({ behavior: 'smooth' })}>
                                        Ventajas
                                    </p>
                                </li>
                                <li class="nav-item">
                                    <p class="linkAb" onClick={(e) => document.getElementById('dicen').scrollIntoView({ behavior: 'smooth' })}>
                                        Reseñas
                                    </p>
                                </li>
                                <li class="nav-item">
                                    <p class="linkAb" onClick={(e) => document.getElementById('preguntas').scrollIntoView({ behavior: 'smooth' })}>
                                        Preguntas Frecuentes
                                    </p>
                                </li>
                                <li class="nav-item">
                                    <p class="linkAb" onClick={(e) => navigate('/terms')}>
                                        Términos y Condiciones
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <main class="">
                {renderizado === undefined ? <div></div> : (ActiveView())}
                <section class="section1" id="precio">
                    <h2 className="center color mb-5">¿Cuánto sale usar <span className="violeta">&nbsp;Sorteo Simple</span>?</h2>
                    <div className="row center pe-5 ps-5">
                        <div className="cardPrecios col-12 col-md-3 col-lg-2 m-2">
                            <h4 className="center color mt-3">Gratis</h4>
                            <div className="d-flex center color">
                                <h3 className="arsPrecio">ARS</h3>
                                <h1 className="precio">0</h1>
                            </div>
                            <h6 className="center mt-4 color text-center">Hasta 300 comentarios</h6>
                            <div className="center mt-4"><button className="w-75 btnPrecio" onClick={(e) => window.scrollTo(0, 0)}>Comenzar</button></div>
                        </div>
                        <div className="cardPrecios col-12 col-md-3 col-lg-2 m-2">
                            <h4 className="center color mt-3">Básico</h4>
                            <div className="d-flex center color">
                                <h3 className="arsPrecio">ARS</h3>
                                <h1 className="precio">2000</h1>
                            </div>
                            <h6 className="center mt-4 color text-center">Hasta 5.000 comentarios</h6>
                            <div className="center mt-4"><button className="w-75 btnPrecio" onClick={(e) => window.scrollTo(0, 0)}>Comenzar</button></div>
                        </div>
                        <div className="cardPrecios col-12 col-md-3 col-lg-2 m-2">
                            <h4 className="center color mt-3">Intermedio</h4>
                            <div className="d-flex center color">
                                <h3 className="arsPrecio">ARS</h3>
                                <h1 className="precio">3000</h1>
                            </div>
                            <h6 className="center mt-4 color text-center">Hasta 10.000 comentarios</h6>
                            <div className="center mt-4"><button className="w-75 btnPrecio" onClick={(e) => window.scrollTo(0, 0)}>Comenzar</button></div>
                        </div>
                        <div className="cardPrecios col-12 col-md-3 col-lg-2 m-2">
                            <h4 className="center color mt-3">Avanzado</h4>
                            <div className="d-flex center color">
                                <h3 className="arsPrecio">ARS</h3>
                                <h1 className="precio">4000</h1>
                            </div>
                            <h6 className="center mt-4 color text-center">Hasta 50.000 comentarios</h6>
                            <div className="center mt-4"><button className="w-75 btnPrecio" onClick={(e) => window.scrollTo(0, 0)}>Comenzar</button></div>
                        </div>
                        <div className="cardPrecios col-12 col-md-3 col-lg-2 m-2">
                            <h4 className="center color mt-3">Influencer</h4>
                            <div className="d-flex center color">
                                <h3 className="arsPrecio">ARS</h3>
                                <h1 className="precio">5000</h1>
                            </div>
                            <h6 className="center mt-4 color text-center">Desde 50.000 comentarios</h6>
                            <div className="center mt-4 color"><button className="w-75 btnPrecio" onClick={(e) => window.scrollTo(0, 0)}>Comenzar</button></div>
                        </div>
                    </div>
                </section>
                <section class="section1" id="utilizar">
                    <h2 className="color m-auto text-center  mb-5">¿Por qué utilizar<span className="violeta">&nbsp;Sorteo Simple</span>? </h2>
                    <div className="row m-auto mb-3">
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img1}
                                    alt="1"
                                    height={"100px"}
                                    width={"100px"}
                                    className="img-fluid"
                                />
                            </div>
                            <h6 className="text-center tituloCard">Sin iniciar sesión</h6>
                            <p className="text-center textCard">
                                Hacé tu sorteo de forma rápida y segura
                                <br /> sin iniciar sesión. Olvídate de compartir
                                <br /> usuarios y contraseñas.
                            </p>
                        </div>
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img2}
                                    alt="2"
                                    height={"100px"}
                                    width={"100px"}
                                />
                            </div>
                            <h6 className="text-center tituloCard mt-2">Pagá con Mercado Pago</h6>
                            <p className="text-center  textCard ">
                                Hacemos las cosas más simples y por eso,
                                vas a poder hacer tus pagos de forma segura y confiable a través
                                de Mercado Pago.
                            </p>
                        </div>
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img3}
                                    alt="3"
                                    height={"100px"}
                                    width={"100px"}
                                />
                            </div>
                            <h6 className="text-center tituloCard">
                                Sorteá en pocos clicks
                            </h6>
                            <p className="text-center textCard mt-3">
                                Diseñamos Sorteo Simple con el objetivo
                                que tus sorteos sean rápidos y sencillos.
                            </p>
                        </div>
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img4}
                                    alt="4"
                                    height={"100px"}
                                    width={"100px"}
                                />
                            </div>
                            <h6 className="text-center tituloCard">
                                Gratis hasta 300 comentarios
                            </h6>
                            <p className="text-center textCard mt-3">
                                La herramienta es completamente gratuita
                                hasta 300 comentarios, asegurando que
                                puedas realizar sorteos sin incurrir en cargos.
                            </p>
                        </div>
                    </div>
                    <div className="row m-auto mb-3">
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img5}
                                    alt="5"
                                    height={"100px"}
                                    width={"100px"}
                                />
                            </div>
                            <h6 className="text-center tituloCard">Selección Aleatoria</h6>
                            <p className="text-center textCard">
                                La herramienta elige aleatoriamente a los ganadores entre los
                                comentarios elegibles, añadiendo un elemento justo e imparcial a
                                tus sorteos.
                            </p>
                        </div>
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img6}
                                    alt="6"
                                    height={"100px"}
                                    width={"100px"}
                                />
                            </div>
                            <h6 className="text-center tituloCard">
                                Filtrado Personalizado
                            </h6>
                            <p className="text-center textCard">
                                Define criterios específicos, y la herramienta filtrará los
                                comentarios según tus preferencias, permitiéndote personalizar
                                el proceso de selección.
                            </p>
                        </div>
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img7}
                                    alt="7"
                                    height={"100px"}
                                    width={"100px"}
                                />
                            </div>
                            <h6 className="text-center tituloCard">Transparencia</h6>
                            <p className="text-center textCard">
                                Demuestra imparcialidad grabando o capturando pantallas de la
                                herramienta en acción, mostrando la naturaleza aleatoria y
                                transparente de la selección de ganadores.
                            </p>
                        </div>
                        <div className="iconContainer ps-3 pe-3 col-12 col-md-4 col-lg-2 m-auto">
                            <div className="center">
                                <img
                                    src={img9}
                                    alt="9"
                                    height={"100px"}
                                    width={"100px"}
                                />
                            </div>
                            <h6 className="text-center tituloCard">Obtené un certificado</h6>
                            <p className="text-center textCard">
                                Al final tu sorteo, vas a poder obtener un certificado con los
                                detalles de los ganadores para compartir con tus seguidores
                            </p>
                        </div>
                    </div>
                </section>
                <section class="section1" id="dicen">
                    <h2 class="color text-center m-auto mb-5">¿Qué dicen de<span className="violeta">&nbsp;Sorteo Simple</span>?</h2>
                    <div class="center row">
                        <div className="col-3 d-none d-lg-block">
                            <div className="center">
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                            </div>
                        </div>
                        <div className="col-3 d-none d-lg-block">
                            <div className="center">
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                            </div>
                        </div>
                        <div className="col-3 d-none d-lg-block">
                            <div className="center">
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                            </div>
                        </div>


                        <div className="col-12 mb-5 d-block d-lg-none ">
                            <div className="center">
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                            </div>
                            <p className=" mt-2 m-0 text-center mb-1  d-flex justify-content-center">"Nos sentimos muy seguros usando esta plataforma. No pide ningún dato. Es muy simple y eficaz."</p>
                            <div className="center">
                                <img src={conociendoba} alt="conociendoba" className="rounded-circle img-fluid" height={50} width={50} />
                                <div className="ps-2">
                                    <p className="m-0">Conociendo BA</p>
                                    <p className="m-0 text-secondary">@conociendoba</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-block d-lg-none mb-5">
                            <div className="center">
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                            </div>
                            <p className="mt-2 m-0 d-flex justify-content-center">"Nos encantó lo fácil y simple que es!!!"</p>
                            <div className="center">
                                <img src={delfino} alt="delfino" className="rounded-circle img-fluid" height={50} width={50} />
                                <div className="ps-2">
                                    <p className="m-0">Conociendo BA</p>
                                    <p className="m-0 text-secondary">@conociendoba</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-block d-lg-none mb-5">
                            <div className="center">
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                                <i class="bi bi-star-fill estrella"></i>
                            </div>
                            <p className="mt-2 m-0 d-flex justify-content-center">"Simple y muy fácil de usar!! Excelente!!"</p>
                            <div className="center">
                                <img src={oddone} alt="oddone" className="rounded-circle img-fluid" height={50} width={50} />
                                <div className="ps-2">
                                    <p className="m-0">Conociendo BA</p>
                                    <p className="m-0 text-secondary">@conociendoba</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="center row mt-1">
                        <div className="col-3 d-none d-lg-block cartaRecomendacion">
                            <p className="cartaRecomendacion1 center text-center">"Nos sentimos muy seguros usando esta plataforma. <br />No pide ningún dato. Es muy simple y eficaz."</p>
                        </div>
                        <div className="col-3 d-none d-lg-block cartaRecomendacion">
                            <p className="mt-2 m-0 cartaRecomendacion1 center text-center">"Nos encantó lo fácil y simple que es!!!"</p>
                        </div>
                        <div className="col-3 d-none d-lg-block cartaRecomendacion">
                            <p className="mt-2 m-0 cartaRecomendacion1 center text-center">"Simple y muy fácil de usar!! Excelente!!"</p>
                        </div>
                    </div>
                    <div class="center row">
                        <div className="col-3 d-none d-lg-block cartaRecomendacion">
                            <div className="center">
                                <img src={conociendoba} alt="conociendoba" className="rounded-circle img-fluid" height={50} width={50} />
                                <div className="ps-2">
                                    <p className="m-0">Conociendo BA</p>
                                    <p className="m-0 text-secondary">@conociendoba</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 d-none d-lg-block cartaRecomendacion">
                            <div className="center">
                                <img src={delfino} alt="delfino" className="rounded-circle img-fluid" height={50} width={50} />
                                <div className="ps-2">
                                    <p className="m-0">Delfino Hogar</p>
                                    <p className="m-0 text-secondary">@delfinohogar</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 d-none d-lg-block cartaRecomendacion">
                            <div className="center">
                                <img src={oddone} alt="oddone" className="rounded-circle img-fluid" height={50} width={50} />
                                <div className="ps-2">
                                    <p className="m-0">Confitería Oddone</p>
                                    <p className="m-0 text-secondary">@confiteriaoddone</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section1 d-none d-lg-block" id="preguntas">
                    <h2 className="center">Preguntas Frecuentes</h2>
                    <div className="row mt-5 pe-5 ps-5 center">
                        <div className={preg === 1 ? "col-12 col-md-4 preguntas" : (preg === 2 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 1 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(1)} className={preg === 1 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué es Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div class={preg === 1 ? "cardPregunta pe-2 ps-3" : "d-none"}>
                                Sorteo Simple nació con la idea de hacer más simples los sorteos
                                de Instagram. <br /> Nos caracterizamos por un proceso de pago
                                seguro a través de Mercado Pago, una navegación rápida y no
                                pedimos usuarios ni contraseñas. <br />
                                De esta manera, nuestros sorteos son seguros, rápidos y
                                sencillos.
                            </div>
                        </div>
                        <div className={preg === 2 ? "col-12 col-md-4 preguntas" : (preg === 1 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 2 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(2)} className={preg === 2 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cómo funcionan los sorteos en Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 2 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Es súper fácil! <br />
                                Solo necesitás seguir unos simples pasos. <br />
                                Primero, pegá el link de la publicación que quieras usar para el
                                sorteo. Si tu sorteo excede los 300 comentarios, realizá tu pago
                                de forma segura a través de Mercado Pago. Después, elige las
                                opciones de configuración y ¡listo!
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 3 ? "col-12 col-md-4 preguntas" : (preg === 4 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 3 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(3)} className={preg === 3 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué hace que Sorteo Simple sea segura?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 3 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                La seguridad es nuestra prioridad. <br />
                                Utilizamos una conexión segura y encriptada para proteger tus
                                datos. Además, no almacenamos tus contraseñas ni accedemos a tu
                                cuenta de Instagram.
                            </div>
                        </div>
                        <div className={preg === 4 ? "col-12 col-md-4 preguntas" : (preg === 3 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 4 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(4)} className={preg === 4 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué diferencia a Sorteo Simple de otras plataformas de sorteos?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 4 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                Sorteo Simple se enfoca en ser rápida y sencilla de usar. No
                                necesitás navegar por un montón de opciones complicadas. <br />
                                Hacemos que el proceso sea lo más intuitivo posible. Además no
                                iniciamos sesión en tu Instagram, no pedimos usuarios ni
                                contraseñas y podés pagar con Mercado Pago!
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 5 ? "col-12 col-md-4 preguntas" : (preg === 6 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 5 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(5)} className={preg === 5 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos con múltiples ganadores?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 5 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Claro que sí! <br />
                                Podés configurar tu sorteo para elegir varios ganadores.
                                Simplemente seleccioná la cantidad deseada en las opciones de
                                configuración.
                            </div>
                        </div>
                        <div className={preg === 6 ? "col-12 col-md-4 preguntas" : (preg === 5 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 6 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(6)} className={preg === 6 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cómo se elige al ganador del sorteo?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 6 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                El ganador se elige de manera aleatoria entre los participantes
                                que cumplan con las condiciones que establezcas en la
                                configuración del sorteo. Esto garantiza un proceso imparcial y
                                transparente.
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 7 ? "col-12 col-md-4 preguntas" : (preg === 8 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 7 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(7)} className={preg === 7 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Necesito descargar alguna aplicación para usar Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 7 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                No es necesario descargar nada. Podés acceder a Sorteo Simple
                                directamente desde el navegador, ya sea en la computadora o un
                                dispositivo móvil.
                            </div>
                        </div>
                        <div className={preg === 8 ? "col-12 col-md-4 preguntas" : (preg === 7 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 8 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(8)} className={preg === 8 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos en mi cuenta de empresa o marca?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 8 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Por supuesto! Sorteo Simple es perfecta para empresas y marcas
                                que deseen realizar sorteos en sus cuentas de Instagram. Solo
                                seguí los pasos y personalizá tu sorteo para cumplir con tus
                                objetivos de marketing.
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 9 ? "col-12 col-md-4 preguntas" : (preg === 10 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 9 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(9)} className={preg === 9 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos de publicaciones en formato Reel de Instagram?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div class={preg === 9 ? "cardPregunta pe-2 ps-3" : "d-none"}>
                                Si, claro. Sorteo Simple funciona para Posts y Reels de
                                Instagram. Simplemente pegá el link y nuestro algoritmo se
                                encargará de determinar a los ganadores!
                            </div>
                        </div>
                        <div className={preg === 10 ? "col-12 col-md-4 preguntas" : (preg === 9 ? 'col-12 col-md-4 preguntas' : "col-12 col-md-4 mb-3")}>
                            <div className={preg === 10 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(10)} className={preg === 10 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cuáles son los medios de contacto?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 10 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                Nos pueden contactar a través de nuestra cuenta oficial de
                                Instagram @sorteosimple. Por favor tener en cuenta que ese es
                                nuestro único medio de contacto.
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section1 d-none d-md-block d-lg-none" id="preguntas">
                    <h2 className="center">Preguntas Frecuentes</h2>
                    <div className="row mt-5 pe-5 ps-5 center">
                        <div className={preg === 1 ? "col-12 col-md-6 preguntas" : (preg === 2 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 1 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(1)} className={preg === 1 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué es Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div class={preg === 1 ? "cardPregunta pe-2 ps-3" : "d-none"}>
                                Sorteo Simple nació con la idea de hacer más simples los sorteos
                                de Instagram. <br /> Nos caracterizamos por un proceso de pago
                                seguro a través de Mercado Pago, una navegación rápida y no
                                pedimos usuarios ni contraseñas. <br />
                                De esta manera, nuestros sorteos son seguros, rápidos y
                                sencillos.
                            </div>
                        </div>
                        <div className={preg === 2 ? "col-12 col-md-6 preguntas" : (preg === 1 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 2 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(2)} className={preg === 2 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cómo funcionan los sorteos en Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 2 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Es súper fácil! <br />
                                Solo necesitás seguir unos simples pasos. <br />
                                Primero, pegá el link de la publicación que quieras usar para el
                                sorteo. Si tu sorteo excede los 300 comentarios, realizá tu pago
                                de forma segura a través de Mercado Pago. Después, elige las
                                opciones de configuración y ¡listo!
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 3 ? "col-12 col-md-6 preguntas" : (preg === 4 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 3 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(3)} className={preg === 3 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué hace que Sorteo Simple sea segura?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 3 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                La seguridad es nuestra prioridad. <br />
                                Utilizamos una conexión segura y encriptada para proteger tus
                                datos. Además, no almacenamos tus contraseñas ni accedemos a tu
                                cuenta de Instagram.
                            </div>
                        </div>
                        <div className={preg === 4 ? "col-12 col-md-6 preguntas" : (preg === 3 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 4 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(4)} className={preg === 4 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué diferencia a Sorteo Simple de otras plataformas de sorteos?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 4 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                Sorteo Simple se enfoca en ser rápida y sencilla de usar. No
                                necesitás navegar por un montón de opciones complicadas. <br />
                                Hacemos que el proceso sea lo más intuitivo posible. Además no
                                iniciamos sesión en tu Instagram, no pedimos usuarios ni
                                contraseñas y podés pagar con Mercado Pago!
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 5 ? "col-12 col-md-6 preguntas" : (preg === 6 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 5 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(5)} className={preg === 5 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos con múltiples ganadores?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 5 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Claro que sí! <br />
                                Podés configurar tu sorteo para elegir varios ganadores.
                                Simplemente seleccioná la cantidad deseada en las opciones de
                                configuración.
                            </div>
                        </div>
                        <div className={preg === 6 ? "col-12 col-md-6 preguntas" : (preg === 5 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 6 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(6)} className={preg === 6 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cómo se elige al ganador del sorteo?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 6 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                El ganador se elige de manera aleatoria entre los participantes
                                que cumplan con las condiciones que establezcas en la
                                configuración del sorteo. Esto garantiza un proceso imparcial y
                                transparente.
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 7 ? "col-12 col-md-6 preguntas" : (preg === 8 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 7 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(7)} className={preg === 7 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Necesito descargar alguna aplicación para usar Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 7 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                No es necesario descargar nada. Podés acceder a Sorteo Simple
                                directamente desde el navegador, ya sea en la computadora o un
                                dispositivo móvil.
                            </div>
                        </div>
                        <div className={preg === 8 ? "col-12 col-md-6 preguntas" : (preg === 7 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 8 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(8)} className={preg === 8 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos en mi cuenta de empresa o marca?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 8 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Por supuesto! Sorteo Simple es perfecta para empresas y marcas
                                que deseen realizar sorteos en sus cuentas de Instagram. Solo
                                seguí los pasos y personalizá tu sorteo para cumplir con tus
                                objetivos de marketing.
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 9 ? "col-12 col-md-6 preguntas" : (preg === 10 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 9 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(9)} className={preg === 9 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos de publicaciones en formato Reel de Instagram?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div class={preg === 9 ? "cardPregunta pe-2 ps-3" : "d-none"}>
                                Si, claro. Sorteo Simple funciona para Posts y Reels de
                                Instagram. Simplemente pegá el link y nuestro algoritmo se
                                encargará de determinar a los ganadores!
                            </div>
                        </div>
                        <div className={preg === 10 ? "col-12 col-md-6 preguntas" : (preg === 9 ? 'col-12 col-md-6 preguntas' : "col-12 col-md-6 mb-3")}>
                            <div className={preg === 10 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(10)} className={preg === 10 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cuáles son los medios de contacto?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 10 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                Nos pueden contactar a través de nuestra cuenta oficial de
                                Instagram @sorteosimple. Por favor tener en cuenta que ese es
                                nuestro único medio de contacto.
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section1 d-block d-md-none" id="preguntas">
                    <h2 className="center">Preguntas Frecuentes</h2>
                    <div className="row mt-5 pe-5 ps-5 center">
                        <div className={preg === 1 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 1 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(1)} className={preg === 1 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué es Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div class={preg === 1 ? "cardPregunta pe-2 ps-3" : "d-none"}>
                                Sorteo Simple nació con la idea de hacer más simples los sorteos
                                de Instagram. <br /> Nos caracterizamos por un proceso de pago
                                seguro a través de Mercado Pago, una navegación rápida y no
                                pedimos usuarios ni contraseñas. <br />
                                De esta manera, nuestros sorteos son seguros, rápidos y
                                sencillos.
                            </div>
                        </div>
                        <div className={preg === 2 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 2 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(2)} className={preg === 2 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cómo funcionan los sorteos en Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 2 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Es súper fácil! <br />
                                Solo necesitás seguir unos simples pasos. <br />
                                Primero, pegá el link de la publicación que quieras usar para el
                                sorteo. Si tu sorteo excede los 300 comentarios, realizá tu pago
                                de forma segura a través de Mercado Pago. Después, elige las
                                opciones de configuración y ¡listo!
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 3 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 3 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(3)} className={preg === 3 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué hace que Sorteo Simple sea segura?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 3 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                La seguridad es nuestra prioridad. <br />
                                Utilizamos una conexión segura y encriptada para proteger tus
                                datos. Además, no almacenamos tus contraseñas ni accedemos a tu
                                cuenta de Instagram.
                            </div>
                        </div>
                        <div className={preg === 4 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 4 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(4)} className={preg === 4 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Qué diferencia a Sorteo Simple de otras plataformas de sorteos?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 4 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                Sorteo Simple se enfoca en ser rápida y sencilla de usar. No
                                necesitás navegar por un montón de opciones complicadas. <br />
                                Hacemos que el proceso sea lo más intuitivo posible. Además no
                                iniciamos sesión en tu Instagram, no pedimos usuarios ni
                                contraseñas y podés pagar con Mercado Pago!
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 5 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 5 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(5)} className={preg === 5 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos con múltiples ganadores?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 5 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Claro que sí! <br />
                                Podés configurar tu sorteo para elegir varios ganadores.
                                Simplemente seleccioná la cantidad deseada en las opciones de
                                configuración.
                            </div>
                        </div>
                        <div className={preg === 6 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 6 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(6)} className={preg === 6 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cómo se elige al ganador del sorteo?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 6 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                El ganador se elige de manera aleatoria entre los participantes
                                que cumplan con las condiciones que establezcas en la
                                configuración del sorteo. Esto garantiza un proceso imparcial y
                                transparente.
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 7 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 7 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(7)} className={preg === 7 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Necesito descargar alguna aplicación para usar Sorteo Simple?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 7 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                No es necesario descargar nada. Podés acceder a Sorteo Simple
                                directamente desde el navegador, ya sea en la computadora o un
                                dispositivo móvil.
                            </div>
                        </div>
                        <div className={preg === 8 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 8 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(8)} className={preg === 8 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos en mi cuenta de empresa o marca?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 8 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                ¡Por supuesto! Sorteo Simple es perfecta para empresas y marcas
                                que deseen realizar sorteos en sus cuentas de Instagram. Solo
                                seguí los pasos y personalizá tu sorteo para cumplir con tus
                                objetivos de marketing.
                            </div>
                        </div>
                    </div>
                    <div className="row center pe-5 ps-5">
                        <div className={preg === 9 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 9 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(9)} className={preg === 9 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Puedo realizar sorteos de publicaciones en formato Reel de Instagram?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div class={preg === 9 ? "cardPregunta pe-2 ps-3" : "d-none"}>
                                Si, claro. Sorteo Simple funciona para Posts y Reels de
                                Instagram. Simplemente pegá el link y nuestro algoritmo se
                                encargará de determinar a los ganadores!
                            </div>
                        </div>
                        <div className={preg === 10 ? "col-12 col-md-4 preguntas" : "col-12 col-md-4 mb-3"}>
                            <div className={preg === 10 ? "btnPreguntas1 d-flex justify-content-between align-items-center ps-3 pe-3" : "btnPreguntas d-flex justify-content-between align-items-center pe-3 ps-3"}>
                                <div ><button onClick={(e) => pregUno(10)} className={preg === 10 ? "btnPreguntas1Boton" : "btnPreguntasBoton"}>
                                    ¿Cuáles son los medios de contacto?
                                </button></div>
                                <i class="bi bi-plus-circle-fill mas"></i>
                            </div>
                            <div
                                class={
                                    preg === 10 ? "cardPregunta pe-2 ps-3" : "d-none"
                                }
                            >
                                Nos pueden contactar a través de nuestra cuenta oficial de
                                Instagram @sorteosimple. Por favor tener en cuenta que ese es
                                nuestro único medio de contacto.
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="footer pt-3 pb-3">
                <div className="d-none d-lg-block">
                    <div className="row footer1">
                        <div className="col-4"><h6 className="text-white linkAb" onClick={(e) => empezar0()}>Comenzar</h6></div>
                        <div className="col-4"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('utilizar').scrollIntoView({ behavior: 'smooth' })}>Ventajas</h6></div>
                        <div className="col-4"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('preguntas').scrollIntoView({ behavior: 'smooth' })}>Preguntas Frecuentes</h6></div>
                    </div>
                </div>
                <div className="d-none d-lg-block">
                    <div className="row footer1">
                        <div className="col-4"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('precio').scrollIntoView({ behavior: 'smooth' })}>Precios</h6></div>
                        <div className="col-4"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('dicen').scrollIntoView({ behavior: 'smooth' })}>Reseñas</h6></div>
                        <div className="col-4"><h6 className="text-white linkAb" onClick={(e) => navigate('/terms')}>Términos y Condiciones</h6></div>
                    </div>
                </div>

                <div className="row footer2 d-block d-lg-none">
                    <div className="col-6"><h6 className="text-white linkAb" onClick={(e) => window.scrollTo(0, 0)}>Comenzar</h6></div>
                    <div className="col-6"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('precio').scrollIntoView({ behavior: 'smooth' })}>Precios</h6></div>
                </div>
                <div className="row footer2 d-block d-lg-none">
                    <div className="col-6"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('utilizar').scrollIntoView({ behavior: 'smooth' })}>Ventajas</h6></div>
                    <div className="col-6"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('dicen').scrollIntoView({ behavior: 'smooth' })}>Reseñas</h6></div>
                </div>
                <div className="row footer2 d-block d-lg-none">
                    <div className="col-6"><h6 className="text-white linkAb" onClick={(e) => document.getElementById('preguntas').scrollIntoView({ behavior: 'smooth' })}>Preguntas Frecuentes</h6></div>
                    <div className="col-6"><h6 className="text-white linkAb" onClick={(e) => navigate('/terms')}>Términos y Condiciones</h6></div>
                </div>
            </footer>
        </div>
    );
}
