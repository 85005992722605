import logo from "./images/Logo.jpg";
import "./App.css";
import { useNavigate } from "react-router-dom";
export default function Terminos() {
    const navigate = useNavigate()
    return (
        <div>
            <nav class="navbar navbar-light bg-light fixed-top pe-5 ps-5">
                <div class="center m-auto">
                    <div class="center">
                        <img src={logo} alt="logo" class="logoHeader" onClick={(e) => navigate('/')} />
                    </div>
                </div>
            </nav>
            <main class="center mt-5 pt-5 color w-75 m-auto">
                <section className="m-auto text-center">
                    <h2 className="mt-3">Términos y Condiciones Completos de Sorteo Simple:</h2>
                    <div className="mt-5">
                        <h4>Aceptación de Términos:</h4>
                        <h5>Al participar en los sorteos organizados por Sorteo Simple, los usuarios aceptan de manera incondicional y completa los términos y condiciones establecidos en este documento. Cualquier participación implica la conformidad con las reglas aquí detalladas.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Proceso de Participación</h4>
                        <h5>Los usuarios pueden ingresar a los sorteos mediante la realización de comentarios específicos en publicaciones designadas en la plataforma Instagram. Se destaca que no es necesario iniciar sesión en Sorteo Simple, lo que preserva la privacidad del usuario y elimina la necesidad de almacenar contraseñas en nuestra base de datos.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Selección de Ganadores:</h4>
                        <h5>Nuestra plataforma emplea un algoritmo avanzado y transparente para determinar los ganadores de manera eficiente. La selección se realiza de forma imparcial, garantizando una competencia justa y equitativa para todos los participantes.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Seguridad de Datos:</h4>
                        <h5>Sorteo Simple se compromete a salvaguardar la información del usuario. En este sentido, no se almacenan contraseñas ni datos sensibles. La privacidad y seguridad de los participantes son principios fundamentales en nuestra operativa.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Pago Seguro</h4>
                        <h5>Para sorteos de mas de 300 comentarios, ofrecemos la posibilidad de participación mediante pagos seguros a través de Mercado Pago. Esta plataforma reconocida en Argentina garantiza transacciones seguras, protegiendo la información financiera de los usuarios y facilitando una experiencia de participación confiable.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Responsabilidad del Usuario:</h4>
                        <h5>Los participantes asumen la responsabilidad de cumplir con los requisitos y reglas específicas de cada sorteo. Sorteo Simple no se hace responsable de participaciones incompletas, incorrectas o que no cumplan con los criterios establecidos.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Condiciones Generales:</h4>
                        <h5>Los participantes se comprometen a respetar las normativas y condiciones generales de Sorteo Simple. Cualquier violación puede resultar en la descalificación del sorteo y, en casos graves, en la prohibición de participar en futuros eventos.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Modificaciones en Términos y Condiciones:</h4>
                        <h5>Sorteo Simple se reserva el derecho de realizar modificaciones en los términos y condiciones en cualquier momento. Los participantes serán notificados previamente sobre cualquier cambio, y la participación continua implica la aceptación de dichas modificaciones.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Límites de Responsabilidad:</h4>
                        <h5>Sorteo Simple no asume responsabilidad por interrupciones del servicio, fallos técnicos, o cualquier evento fuera de su control que pueda afectar la realización de los sorteos. En tales casos, nos comprometemos a resolver cualquier inconveniente de la manera más justa y eficiente posible. Por favor contáctenos a través de redes sociales con una captura de pantalla o foto de su error y con gusto lo ayudaremos.</h5>
                    </div>
                    <div className="mt-5">
                        <h4>Participación Responsable:</h4>
                        <h5>Sorteo Simple fomenta la participación responsable. Se insta a los usuarios a no utilizar prácticas indebidas, como la creación de cuentas falsas o el spam, para aumentar sus posibilidades de ganar. Aquellos que se encuentren participando de manera no ética podrán ser descalificados y excluidos de futuros sorteos.</h5>
                    </div>
                    <h5>Al participar en Sorteo Simple, los usuarios reconocen haber leído, entendido y aceptado en su totalidad estos términos y condiciones.</h5>
                </section>
            </main>
        </div>
    );
}
